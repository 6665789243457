<template>
  <div>
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="ics_o_announce">
        <g id="Vector">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M10.7795 4.91266C10.5427 4.67422 10.5427 4.28763 10.7795 4.04919L12.0661 2.75399C12.3029 2.51555 12.6869 2.51555 12.9238 2.75399C13.1606 2.99243 13.1606 3.37902 12.9238 3.61746L11.6372 4.91266C11.4004 5.1511 11.0164 5.1511 10.7795 4.91266Z"
            fill="#FAB713"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M8.24162 3.96893C7.91808 3.88165 7.72608 3.54686 7.81277 3.22114L8.28368 1.45187C8.37037 1.12615 8.70293 0.932857 9.02647 1.02013C9.35001 1.10741 9.54201 1.4422 9.45532 1.76792L8.98441 3.53719C8.89771 3.86291 8.56516 4.0562 8.24162 3.96893Z"
            fill="#FAB713"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M11.7169 7.46759C11.6302 7.14188 11.8222 6.80708 12.1458 6.71981L13.9032 6.24573C14.2268 6.15846 14.5593 6.35175 14.646 6.67747C14.7327 7.00318 14.5407 7.33798 14.2172 7.42525L12.4597 7.89933C12.1362 7.9866 11.8036 7.79331 11.7169 7.46759Z"
            fill="#FAB713"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M5.59163 3.88046C5.79144 3.83294 6.00005 3.83762 6.19752 3.89402C6.39498 3.95042 6.57436 4.05654 6.71885 4.20185L11.2511 8.76457C11.3954 8.91 11.5003 9.09 11.5557 9.2868C11.6112 9.48359 11.6158 9.69128 11.5691 9.8903C11.5224 10.0893 11.4258 10.2735 11.2881 10.4252C11.1505 10.5769 10.9761 10.6911 10.7815 10.7564L8.08576 11.6612L8.9851 12.5666C9.20978 12.7928 9.33521 13.0987 9.33521 13.4166C9.33521 13.7346 9.20978 14.0405 8.9851 14.2667L8.60754 14.6468C8.42963 14.8259 8.19951 14.9448 7.94973 14.9856C7.69989 15.0265 7.44377 14.987 7.21788 14.8732L4.94902 13.7307C4.5617 14.0177 4.09046 14.1756 3.60246 14.1756C2.99925 14.1756 2.42165 13.9343 1.99648 13.5063C1.57147 13.0784 1.3335 12.499 1.3335 11.8958C1.3335 11.2926 1.57147 10.7133 1.99648 10.2854L3.30709 8.96597L4.72915 4.67256C4.79347 4.47844 4.90619 4.30352 5.05701 4.16477C5.20786 4.026 5.39183 3.92798 5.59163 3.88046ZM5.92701 5.29665L10.1604 9.55847L7.04581 10.6039L4.89617 8.40888L5.92701 5.29665ZM6.92685 12.388L6.82286 12.2818L6.63406 12.0917L5.96779 12.7509L7.72997 13.6383L7.95011 13.4166L6.9276 12.3873L6.92685 12.388ZM4.31205 12.5167L4.26248 12.5666C4.08666 12.7436 3.84916 12.8422 3.60246 12.8422C3.35576 12.8422 3.11826 12.7436 2.94245 12.5666C2.76648 12.3895 2.66683 12.1483 2.66683 11.8958C2.66683 11.6434 2.76648 11.4022 2.94245 11.225L4.37526 9.78259L5.70248 11.1378L4.31036 12.515L4.31205 12.5167Z"
            fill="#FAB713"
          />
        </g>
      </g>
    </svg>
  </div>
</template>

<script>
import { Vue } from "vue-class-component";

export default class IconAnnounce extends Vue {}
</script>
